import { useApolloClient, resetApolloContext } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { shutdown, hide } from '@intercom/messenger-js-sdk';

const useLogout = () => {
  const client = useApolloClient();
  const history = useRouter();

  const logout = () => {
    Cookies.remove('token');
    client.cache.writeData({
      data: {
        breadcrumbs: [],
        savedStatus: '',
        opportunityFilters: {
          __typename: 'OpportunityFilters',
          pageNumber: 1,
          tag: null,
          type: null,
          clients: null,
          status: null,
        },
        consultant: {
          __typename: 'Consultant',
          consultantId: '',
        },
      },
    });

    hide();
    shutdown();
    history.push('/');
  };

  return [logout];
};

export const logout = () => {
  Cookies.remove('token');
  resetApolloContext();
  window.location.href = '/';
};

export default useLogout;
